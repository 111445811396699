import { Box, Card, SxProps, Typography } from "@mui/material";
import Logo from "../../../components/general/Logo";
import { DEMANDORIA_SUBDOMAINS } from "../../../utils/stringUtils";
import { dmBrighterOrange, generalDarkGrey } from "../../../styles/colors";
import BeautyIcon from "../../../assets/img/beauty-card.png";
import PetIcon from "../../../assets/img/pet-card.png";
import TurismIcon from "../../../assets/img/turism-card.png";
import Footer from "../../../components/general/Footer";
import { CSSProperties } from "react";

const GeneralLandingPage = () => {
  const checkIfIsInSubdomain = (): boolean => {
    return (
      window.location.host.includes(DEMANDORIA_SUBDOMAINS[0]) ||
      window.location.host.includes(DEMANDORIA_SUBDOMAINS[1]) ||
      window.location.host.includes(DEMANDORIA_SUBDOMAINS[2])
    );
  };
  const handleNicheClick = (nicheName) => {
    if (checkIfIsInSubdomain())
      window.location.href = `http://${nicheName.toLowerCase()}.${
        window.location.host.split(".")[1]
      }/`;
    else
      window.location.href = `http://${nicheName.toLowerCase()}.${
        window.location.host
      }/`;
  };

  const NicheCard = ({ children, onClickAction }) => {
    return (
      <Card
        variant="outlined"
        sx={{
          ...styles.card,
          transition: "color 0.2s ease, font-weight 0.3s ease",
          ":hover": { color: dmBrighterOrange, fontWeight: "bold" },
          ":hover img": {
            transform: "scale(1.1)",
          },
        }}
        onClick={onClickAction}
      >
        {children}
      </Card>
    );
  };

  const beautyCard = (
    <NicheCard
      children={
        <Box sx={styles.cardContent}>
          <img src={BeautyIcon} alt="beauty icon" style={styles.cardImage} />
          <Typography variant="h6">Beleza</Typography>
        </Box>
      }
      onClickAction={() => handleNicheClick("beleza")}
    />
  );

  const petCard = (
    <NicheCard
      children={
        <Box sx={styles.cardContent}>
          <img src={PetIcon} alt="pet icon" style={styles.cardImage} />
          <Typography variant="h6">Pet</Typography>
        </Box>
      }
      onClickAction={() => handleNicheClick("pet")}
    />
  );

  const turismCard = (
    <NicheCard
      children={
        <Box sx={styles.cardContent}>
          <img src={TurismIcon} alt="turism icon" style={styles.cardImage} />
          <Typography variant="h6">Turismo</Typography>
        </Box>
      }
      onClickAction={() => handleNicheClick("turismo")}
    />
  );

  return (
    <>
      <Box sx={styles.mainContainer}>
        <Box sx={styles.headerContainer}>
          <Logo />
          <Typography variant="h6" color={generalDarkGrey}>
            Selecione o nicho desejado
          </Typography>
        </Box>
        <Box sx={styles.cardsContainer}>
          {beautyCard}
          {petCard}
          {turismCard}
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default GeneralLandingPage;

const styles = {
  mainContainer: {
    width: "100%",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 5,
  } as SxProps,

  headerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  } as SxProps,

  cardsContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    gap: 5,
    "@media(max-width:900px)": {
      flexDirection: "column",
      alignItems: "center",
    },
  } as SxProps,

  card: {
    width: "300px",
    cursor: "pointer",
    borderRadius: "12px",
  } as SxProps,

  cardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 4,
    padding: "40px 0px",
  } as SxProps,

  cardImage: {
    width: "150px",
    height: "150px",
    transition: "transform 0.3s ease",
  } as CSSProperties,
};
